<template>
  <div>
    <h3 class="flix-html-h3">{{ $t('message.startDate') }}</h3>
    <transition name="flixFadeIn">
      <div :key="start">
        <a class="flix-form-control flix-html-a" href="#"  v-if="!start" @click.prevent="setStart()">Sofort</a>
        <div v-if="start">
          <dayPicker :date="start" :callback="function(date){callback(date)}" />
          <div>
            <a class="flix-html-a flix-text-danger" href="#" @click.prevent="start = ''; callback(start)">Startdatum entfernen</a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    dayPicker () { return import('@/components/assistent/components/assets/daypicker') }
  },
  props: { data: Object, callback: Function },
  data () {
    return {
      start: this.data.start
    }
  },
  watch: {
  },
  methods: {
    setStart () {
      var d = new Date()
      d.setDate(d.getDate() + 1)
      this.start = d.getFullYear() + '-' + this.$getNullsBefore(d.getMonth() + 1) + '-' + this.$getNullsBefore(d.getDate()) + ' ' + this.$getNullsBefore(d.getHours()) + ':00'
      this.callback(this.start)
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
